<template>
  <div class="mx-4">
    <!-- <v-divider class="mt-4"></v-divider> -->
    <v-divider class="mb-10"></v-divider>
    <v-row justify="center" v-if="$route.name === 'Home'">
      <v-col cols="12" align="center">
        <div class="text-center text-h4">Sign up for our Newsletter</div>
        <iframe
          id="mc_embed_signup"
          src="https://keap.app/contact-us/1842863144950258"
          frameborder="0"
        >
          ></iframe
        >
        <!-- <di
          data-form-slug="1842863144950258"
          data-env="production"
          data-path="contact-us/1842863144950258"
          class="keap-custom-form"
        ></di> -->
      </v-col>

      <v-divider></v-divider>
    </v-row>
    <v-row justify="center">
      <v-col cols="11">
        <v-card-title class="justify-center text-h4 text-decoration-underline"
          >Useful Links</v-card-title
        >
        <v-row justify="space-around">
          <v-col v-for="(l, i) in isThisRoute" :key="i" cols="auto">
            <v-chip color="blue lighten-1" outlined @click="$router.push(l.link)">
              <v-icon left v-text="l.icon"></v-icon>
              {{ l.name }}</v-chip
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider inset class="my-4"></v-divider>
    <p class="font-weight-thin text-center">
      &copy; {{ fullYear }} GraceWay Radio
      <span class="caption ml-3"> <a href="/privacy-policy">Privacy Policy</a></span>
      <span class="caption ml-3"> <a href="mailto:admin@gracewayradio.com">Contact Us</a></span>
    </p>
    <p class="font-weight-thin text-center mb-0 text-caption">
      GraceWay Radio is an affiliate of CDM. CDM is a registered 501(c)3 Non-Profit organization. As
      a 501(c)3 all donations are tax-deductible. EIN: 75-2340922 | All rights reserved
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    links: [
      {
        name: "Testimonies",
        link: "/testimonies",
        icon: "mdi-lead-pencil",
      },
      {
        name: "Featured Sermon",
        link: "/weekly-message",
        icon: "mdi-microphone-outline",
      },
      {
        name: "Links",
        link: "/how-to-listen",
        icon: "mdi-link-variant",
      },
      {
        name: "Speakers",
        link: "/featured-speakers",
        icon: "mdi-account-voice",
      },
      {
        name: "Schedule",
        link: "/program-schedule",
        icon: "mdi-calendar-month-outline",
      },
      {
        name: "Blog",
        link: "/blog",
        icon: "mdi-newspaper-variant-outline",
      },
      {
        name: "Donate",
        link: "/donate",
        icon: "mdi-credit-card-outline",
      },
    ],
  }),
  components: {},
  computed: {
    fullYear() {
      const a = new Date();
      return a.getFullYear();
    },
    isThisRoute() {
      return this.links.filter((b) => b.link !== this.$route.path);
    },
  },
};
</script>

<style lang="scss" scoped>
#mc_embed_signup {
  height: 800px;
  width: min-content;
  padding-inline: 20px;
  margin: auto;
  font: 14px Helvetica, Arial, sans-serif;

  input[type="text"],
  input[type="email"] {
    line-height: 1.4;
    margin-bottom: 0.8rem;
    width: 100%;
    padding: 0.4rem;
    background: rgb(250, 250, 250);
    border-radius: 8px;
    outline: none;
    border: 1px solid #d3d3d3;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.09);
    transition: all 0.3s ease-out;

    &:focus {
      box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.5);
      background: transparent;
    }
  }

  .button {
    background: #0689b1;
    border-radius: 8px;
    border: none;
    transition: background 0.3s ease-out;
    width: 100%;

    &:hover {
      background: #10b2d3;
    }
  }
}
</style>
