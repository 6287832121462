import axios from "axios";

const features = {
  namespaced: true,
  state: {
    features: [],
    featureDialog: false,
  },
  mutations: {
    SET_FEATURES_RESULTS(_state, _features) {
      _state.features = _features;
    },
    SET_NO_FEATURE_DIALOG(_state, _value) {
      _state.featureDialog = _value;
    },
  },
  actions: {
    getFeatures({ commit, dispatch }) {
      // const url = "http://127.0.0.1:8000/featurette/current_feature";
      const url = "https://gwrapi.herokuapp.com/featurette/current_feature";
      axios
        .get(url)
        .then((res) => {
          if (res && res?.data) {
            // const featuresObj = res?.data?.map(feature => ({
            //   slug: feature.slug,
            //   image: feature.image,
            //   title: feature.title,
            //   content: feature.content,
            //   link: feature.link,
            //   startPublish: feature.start_publish_date,
            //   endPublish: feature.end_publish_date
            // }));
            // console.log("Object= ", featuresObj)
            commit("SET_FEATURES_RESULTS", res.data);
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch("toggleFeatureDialog", true);
          } else {
            console.error("getFeatures: ", err);
          }
        });
    },
    toggleFeatureDialog({ commit }, value) {
      commit("SET_NO_FEATURE_DIALOG", value);
    },
  },
};

export default features;
