<template>
  <v-tooltip v-if="audioIsPlaying" top color="blue darken-1">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        class="fader"
        fab
        dark
        @click.stop="setQuality"
        :style="btnStyle"
        :absolute="btnAbsolute"
        :small="btnXsmall"
      >
        <span>{{ radioQuality }}</span>
      </v-btn>
    </template>
    <span>Change to {{ changeQuality }} Quality</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "RadioQualityFabButton",
  data: () => ({}),
  props: {
    btnStyle: {
      type: [String, Object],
    },
    btnAbsolute: {
      type: Boolean,
    },
    btnXsmall: {
      type: Boolean,
    },
  },
  methods: {
    setQuality() {
      let quality;
      if (this.radioQuality === "hq") quality = "mq";
      if (this.radioQuality === "mq") quality = "lq";
      if (this.radioQuality === "lq") quality = "hq";
      this.$store.dispatch("audio/setQuality", quality);
    },
  },
  computed: {
    audioIsPlaying() {
      return this.$store?.state?.audio?.audioIsPlaying;
    },
    radioQuality() {
      return this.$store?.state?.audio?.quality;
    },
    changeQuality() {
      if (this.radioQuality === "hq") {
        return "Med";
      } else if (this.radioQuality === "mq") {
        return "Low";
      } else {
        return "High";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fade {
  0% {
    opacity: 0.9;
    background: #28baf0;
  }
  50% {
    opacity: 0.4;
    background: #1699fb;
  }
  100% {
    opacity: 0.9;
    background: #28baf0;
  }
}
.fader {
  animation: fade 5s ease-in-out infinite;
}
</style>
