import axios from "axios";
import apiURL from "../../config/urlconfig";

const songObject = {
  namespaced: true,
  state: {
    // SONG OBJECT RELATED STATE
    song: null,
    songLoading: false,
    // -----------------------------------------------
  },
  mutations: {
    SET_SONG_FROM_API(_state, _loading) {
      _state.songLoading = _loading;
    },
    SET_SONG(_state, _song) {
      _state.song = _song;
    },
  },
  actions: {
    removeFetchedSong({ commit }) {
      commit("SET_SONG", null);
    },
    getSong({ commit }, songId) {
      commit("SET_SONG_FROM_API", true);
      const url = `${apiURL}/api/gettrack`;

      axios
        .post(url, { trackid: songId })
        .then((res) => {
          commit("SET_SONG", res?.data);
          return res?.data;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          return null;
        })
        .finally(() => {
          commit("SET_SONG_FROM_API", false);
        });
    },
  },
};

export default songObject;
