<template>
  <div :id="isChristmas ? 'christmas-container' : 'container'">
    <v-card
      :id="isChristmas ? 'christmas-main-card' : 'main-card'"
      :width="isMobile ? 'min-content' : 'fit-content'"
      class="mx-auto"
    >
      <v-overlay absolute v-if="$store.state.feedbackLoading || loadingSong">
        <p class="text-center">Fetching...</p>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-overlay>
      <div
        class="d-flex justify-space-between"
        :class="!isMobile ? 'flex-no-wrap' : 'flex-wrap-reverse'"
      >
        <div class="my-auto">
          <v-card-actions v-if="isAdmin" class="py-0">
            <v-spacer v-if="playingNow" />
            <v-btn v-if="playingNow" class="text-capitalize" text dark @click="skipTrack">
              Skip Track
              <v-icon right small>mdi-skip-next</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="text-capitalize"
              text
              color="error"
              dark
              :disabled="playingNow || isTrailer"
              @click="deleteDialogActive = true"
              >Delete Song</v-btn
            >
            <v-spacer />
            <v-btn
              v-if="!isInHistory && !isInQueue && !playingNow"
              class="text-capitalize"
              text
              dark
              :disabled="playingNow || isTrailer"
              @click="makeRequest(activeSong.trackid, true)"
              >Play Next</v-btn
            >
          </v-card-actions>
          <v-card-title :class="!isMobile ? 'mt-2' : 'order-2; mobile-card'">
            <v-icon dark :size="isMobile ? 'small' : 'medium'" class="mr-2">mdi-music-note</v-icon>
            <span @click="requestArtist(activeSong.title)" class="artist-name">{{
              activeSong && activeSong.title ? activeSong.title : ""
            }}</span>
          </v-card-title>
          <v-card-subtitle
            :class="!isMobile ? '' : 'order-3 mobile-card'"
            v-if="activeSong && activeSong.artist"
          >
            <v-icon dark :size="isMobile ? 'small' : 'medium'" class="mr-2">mdi-account</v-icon>
            <span
              v-for="(artist, i) in activeSong.artist.split(';')"
              :key="i"
              class="artist-name"
              @click="requestArtist(artist)"
              >{{ artistName(artist, i) }}</span
            >
          </v-card-subtitle>
          <v-card-text :class="!isMobile ? '' : 'mobile-card'">
            <v-icon dark :size="isMobile ? 'small' : 'medium'" class="mr-2">mdi-album</v-icon>
            <span @click="requestArtist(activeSong.album)" class="artist-name">{{
              activeSong && activeSong.album ? activeSong.album : ""
            }}</span>
          </v-card-text>
          <div class="ml-4" :class="!isMobile ? '' : 'mobile-card'">
            <v-icon dark :size="isMobile ? 'small' : 'medium'" class="mr-2"
              >mdi-clock-outline</v-icon
            >
            {{ activeSong && activeSong.duration ? activeSong.duration.replace(/^0+/, "") : "" }}
          </div>
          <v-card-actions :class="!isMobile ? 'mb-2' : 'mb-2 order-4'">
            <v-btn @click="closeSongInfo" dark icon small>
              <v-icon dark>mdi-close</v-icon>
            </v-btn>
            <v-btn
              v-if="
                !isInQueue &&
                !isInHistory &&
                !playingNow &&
                activeSong &&
                activeSong.type &&
                (isChristmas ? activeSong.type === 'christmas' : activeSong.type === 'song')
              "
              class="text-capitalize"
              outlined
              rounded
              :loading="requestLoading"
              :disabled="requestPending"
              dark
              @click="makeRequest(activeSong.trackid)"
              >Request Song</v-btn
            >
            <v-btn v-if="!isTrailer" small icon @click="toggleFavorite()">
              <v-icon dark color="error" v-if="favorited">mdi-heart</v-icon>
              <v-icon dark v-else color="red">mdi-heart-outline</v-icon>
            </v-btn>
            <v-chip v-if="isInQueue" class="ma-2" dark color="red" label outlined>
              <v-icon dark class="pr-2">mdi-page-next</v-icon>Upcoming
            </v-chip>
            <v-chip v-else-if="playingNow" class="ma-2" dark label outlined>
              <v-icon dark class="pr-2">mdi-music</v-icon>Playing
            </v-chip>
            <v-chip v-else-if="isInHistory" class="ma-2" dark color="orange" label outlined>
              <v-icon dark class="pr-2">mdi-page-previous</v-icon>Recently Played
            </v-chip>
            <v-chip v-else-if="isRequested" dark pill color="green" class="ma-2">
              <v-icon dark left>mdi-alpha-r-circle</v-icon>Requested
            </v-chip>
            <v-btn v-if="!isTrailer" @click="toggleLiked('liked')" icon small>
              <v-icon dark color="orange" v-if="isLiked">mdi-thumb-up</v-icon>
              <v-icon dark color="blue" v-else>mdi-thumb-up</v-icon>
            </v-btn>
            <v-btn v-if="!isTrailer" small icon @click="toggleLiked('disliked')">
              <v-icon dark color="red" v-if="isDisliked">mdi-thumb-down</v-icon>
              <v-icon dark v-else color="blue">mdi-thumb-down</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
        <v-avatar
          :size="isMobile ? 320 : 250"
          tile
          :class="!isMobile ? 'ma-3' : 'order-1 mx-auto ma-3 pa-2'"
        >
          <v-img
            v-if="activeSong"
            :src="itemImg"
            class="white--text"
            contain
            :alt="activeSong.album"
          >
            <v-overlay absolute :value="isRequested">
              <v-chip class="ma-2" dark color="white" label outlined>
                <v-icon dark left>mdi-rotate-right mdi-spin</v-icon>
                {{ getTheStatus }} to go
              </v-chip>
            </v-overlay>
          </v-img>
        </v-avatar>
      </div>
    </v-card>
    <v-dialog v-model="deleteDialogActive" width="500" hide-overlay>
      <v-card>
        <v-toolbar color="primary" dark class="text-h5 d-flex justify-center">
          Delete Track From Library
        </v-toolbar>
        <v-card-text class="pa-4 text-center">
          <h3>Artist: {{ activeSong?.artist }}</h3>
          <h3>Title: {{ activeSong?.title }}</h3>
          <h3>Album: {{ activeSong?.album }}</h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn color="primary" class="text-capitalize" text @click="deleteDialogActive = false">
            Cancel
          </v-btn>
          <v-btn color="error" class="text-capitalize" text @click="deleteTrack">Send It!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar :value="!!snackbarText" color="success" class="d-flex">
      <v-progress-circular v-if="loading" indeterminate class="mr-2 d-inline-flex" />
      <div class="d-inline-flex white--text text-h5 text-center">{{ snackbarText }}</div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SongInfoCard",
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isChristmas: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    liked: false,
    disliked: false,
    skipTrackMessage: "",
    deleteTrackMessage: "",
    deleteDialogActive: false,
    loading: false,
  }),
  computed: {
    snackbarText() {
      return this.loading ? "Please wait" : this.skipTrackMessage || this.deleteTrackMessage;
    },
    requestServiceIsDown() {
      return this.$store.state.requestServiceIsDown;
    },
    loadingSong() {
      return this.$store.state.songObject.songLoading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    trailerIds() {
      return this.$store.getters.trailerIds;
    },
    isTrailer() {
      const { type } = this.activeSong || {};

      return ["sermon", "trailer", "podcast"].includes(type);
    },
    itemImg() {
      return this.$store.getters.itemImg(this.activeSong);
    },
    playingNow() {
      return this.activeSong?.trackid === this.$store.state.songInfo.trackid;
    },
    isInQueue() {
      if (this.$store.state.queue) {
        return (
          this.activeSong?.trackid &&
          this.$store.state?.queue.some((song) => song.trackid === this.activeSong?.trackid)
        );
      }
      return false;
    },
    isInHistory() {
      return (
        this.activeSong?.trackid &&
        this.$store.state.history.some((song) => song.trackid === this.activeSong.trackid)
      );
    },
    isRequested() {
      const lastrequested = new Date(this.activeSong?.lastrequested);
      const lastplayed = new Date(this.activeSong?.lastplayed);
      return !this.playingNow && this.isInQueue && lastrequested > lastplayed;
    },
    getTheStatus() {
      const theOne = this.$store.state.queue?.findIndex(
        (song) => song.trackid === this.activeSong?.trackid
      );
      return theOne + 1;
    },
    activeSong() {
      return this.$store.state.activeSong;
    },
    requestLoading() {
      return this.$store.state.songRequests.requestLoading;
    },
    requestPending() {
      return !!this.$store.state.songRequests.requestHeader.length;
    },
    favorited() {
      return this.$store.state.mySongs?.favorites.some(
        (song) => song.trackid === this.activeSong?.trackid
      );
    },
    isLiked() {
      return this.$store.state.mySongs?.rated.some(
        (song) => song?.trackid === this.activeSong?.trackid && song.rating === "liked"
      );
    },
    isDisliked() {
      return this.$store.state.mySongs?.rated.some(
        (song) => song?.trackid === this.activeSong?.trackid && song.rating === "disliked"
      );
    },
  },
  methods: {
    async skipTrack() {
      this.skipTrackMessage = await this.$store.dispatch("admin/skipTrack");
      setTimeout(() => {
        this.skipTrackMessage = "";
      }, 3000);
    },
    async deleteTrack() {
      if (!this.activeSong?.trackid) return;
      this.loading = true;
      this.deleteTrackMessage = await this.$store.dispatch(
        "admin/deleteSong",
        this.activeSong.trackid
      );
      this.loading = false;
      setTimeout(() => {
        this.deleteDialogActive = false;
        this.deleteTrackMessage = "";
      }, 3000);
    },
    artistName(artist, index) {
      return index < this.activeSong.artist.split(";").length - 1 ? artist + ", " : artist;
    },
    requestArtist(artistName) {
      if (!this.isTrailer) {
        this.$router.replace({ name: "Requests", params: { query: artistName } });
        this.closeSongInfo();
      }
    },
    closeSongInfo() {
      this.$store.dispatch("viewSongInfo", null);
      this.$store.dispatch("songObject/removeFetchedSong");
    },
    toggleFavorite() {
      this.$store.dispatch("toggleFavorite", this.activeSong);
    },
    toggleLiked(rating) {
      this.$store.dispatch("toggleRated", { songObj: this.activeSong, rating });
    },
    // need to adjust this method since isRequested is now different
    // not sure if that's gunna work.
    makeRequest(trackid, playNext) {
      // if (this.requestLoading || this.isRequested) {
      //   return;
      // }
      if (this.requestLoading) {
        return;
      }
      const requestObj = {
        trackid,
        searchTerm: this.$store.state.songRequests.searchTerm,
        playNext,
      };
      this.$store.dispatch("songRequests/makeRequest", requestObj).then(() => {
        setTimeout(() => {
          this.closeSongInfo();
        }, 8000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#christmas-container {
  background-image: url("../../assets/christmas.jpg");
  background-size: cover;
  background-position: center center;
}
#christmas-main-card {
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  backdrop-filter: blur(1px);
}
.artist-name {
  cursor: pointer;
}
.artist-name:hover {
  text-decoration: underline;
}
.mobile-card {
  font-size: 0.7rem;
}
#container {
  background-size: cover;
  background-position: center center;
}
#main-card {
  background-color: #0d1755;
  color: white;
}
</style>
