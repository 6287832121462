<template>
  <div>
    <v-list dense>
      <v-list-item>
        <v-btn-toggle dense class="mx-auto" v-model="historyUpcoming" color="primary accent-3" group mandatory>
          <v-btn class="text-body-1 font-weight-bold text-capitalize" value="upcoming">Upcoming</v-btn>
          <v-btn class="text-body-1 font-weight-bold text-capitalize" value="history">History</v-btn>
        </v-btn-toggle>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list two-line>
      <div v-for="(song, i) in historyUpcoming === 'history' ? history : queue" :key="i">
        <v-list-item style="cursor: pointer" @click="viewSongInfo(song)">
          <v-list-item-content>
            <v-row justify="start" align="start" class="mt-n2 ml-0 pa-0">
              <v-col class="ma-0 pa-0">
                <v-chip v-if="requested(song)" dark color="green" label pill x-small class="mr-2">
                  <v-icon>mdi-alpha-r-circle-outline</v-icon>
                </v-chip>
                <v-icon v-if="isOneOfMyFavorites(song)" class="mr-1" color="red">mdi-18px mdi-cards-heart</v-icon>
                <v-icon v-if="isLiked(song)" class="mr-1" color="orange">mdi-18px mdi-thumb-up</v-icon>
              </v-col>
            </v-row>
            <v-list-item-title>{{ song.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ song.artist | separate }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-if="i !== history.length - 1"></v-divider>
      </div>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "HistoryQueue",
  data: () => ({
    historyUpcoming: "upcoming",
  }),
  computed: {
    queue() {
      return this.$store.state.queue;
    },
    history() {
      return this.$store.state.history;
    },
  },
  methods: {
    isLiked(item) {
      return this.$store.state.mySongs?.rated.some(
        (song) => song?.trackid === item.trackid && song.rating === "liked"
      );
    },
    requested(item) {
      const { lastplayed, lastrequested } = item;

      return (!lastplayed && lastrequested) || (lastrequested && new Date(lastrequested) > new Date(lastplayed));
    },
    isOneOfMyFavorites(item) {
      return this.$store.state.mySongs?.favorites.some((song) => song.trackid === item.trackid);
    },
    viewSongInfo(songObj) {
      this.$store.dispatch("viewSongInfo", songObj);
    },
  },
};
</script>
