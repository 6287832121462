/* eslint-disable no-param-reassign */
// import { Magic } from 'magic-sdk';
// import router from "../router";
// const m = new Magic(process.env.VUE_APP_MAGIC_KEY);
// import { MagicIncomingWindowMessage } from "@magic-sdk/types";
// import { bootstrap } from "vue-gtag";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import apiURL from "../config/urlconfig";
import socket from "../socket";
import createWebSocketPlugin from "../websocketStoreplugin";
import admin from "./admin/index.js";
import analytics from "./analytics/";
import audio from "./audio/index.js";
import blog from "./blog/index.js";
import features from "./features/index.js";
import requests from "./requests/index.js";
import slides from "./slides/index.js";
import songObject from "./songObject/index.js";
import testimony from "./testimony/index.js";
import verse from "./verse/index.js";

Vue.use(Vuex);

const websocketPlugin = createWebSocketPlugin(socket);

export default new Vuex.Store({
  plugins: [websocketPlugin],
  modules: {
    admin,
    audio,
    verse,
    songObject,
    blog,
    analytics,
    songRequests: requests,
    testimonyObjects: testimony,
    slideObjects: slides,
    featureObjects: features,
  },
  state: {
    //  OTHER GATHERED CONTENT
    songFetchInterval: 10000,
    feedbackLoading: false,
    loginMessage: "",
    cdnUrl: "",
    // ---------------------------------------------
    // INFO.JSON RETURN INFO
    songInfo: null,
    activeSong: null,
    loadingSongInfo: false,
    history: [],
    queue: [],
    requests: [],
    // ---------------------------------
    // Testimonies
    // ----------------------------
    // USER DATA
    mySongs: {
      rated: [],
      favorites: [],
    },
    sermonTimes: {},
  },
  mutations: {
    SET_CDN_URL(_state, _url) {
      _state.cdnUrl = _url;
    },
    SET_REQUESTS(_state, _requests) {
      _state.requests = _requests;
    },
    SET_ACTIVE_SONG(_state, _activeSong) {
      _state.activeSong = _activeSong;
    },
    CLEAR_AUTHORIZATION() {
      localStorage.removeItem("authToken");
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    },
    SET_AUTH_TOKEN(_value) {
      localStorage.setItem("authToken", _value)
    },
    SET_FETCH_INTERVAL(_state, _interval) {
      if (_interval && typeof _interval === "number" && _state.songFetchInterval !== _interval) {
        _state.songFetchInterval = _interval;
      }
    },
    SET_SONG_OBJECT(_state, _song) {
      _state.songInfo = _song;
    },
    SET_SONG_HISTORY(_state, _history) {
      _state.history = _history;
    },
    SET_SONG_QUEUE(_state, _queue) {
      _state.queue = _queue;
    },
    ADD_SONG_TO_QUEUE(_state, _value) {
      _state.queue.push(_value);
    },
    SET_SONG_LOADING(_state, _load) {
      _state.loadingSongInfo = _load;
    },
    SET_FEEDBACK_LOADING(_state, _status) {
      _state.feedbackLoading = _status;
    },
    INITIALIZE_STORE(_state) {
      // consolidate this TODO
      if (
        localStorage.getItem("savedSongs") &&
        !Array.isArray(localStorage.getItem("savedSongs"))
      ) {
        try {
          const stored = JSON.parse(localStorage.getItem("savedSongs"));
          _state.mySongs = { ...stored };
        } catch (e) {
          console.error(e || "savedSongs initialization error");
        }
      }
      try {
        const sermonTimes = localStorage.getItem("sermonProgress");

        if (sermonTimes) {
          _state.sermonTimes = JSON.parse(sermonTimes);
        }
      } catch (e) {
        console.error(e || "sermonProgress initialization error");
      };
    },
    SET_SERMON_TIMES(_state, _sermonTimes) {
      _state.sermonTimes = _sermonTimes;
    },
    ADD_SONG_TO_FAVORITES(_state, _songObj) {
      if (!_state.mySongs.favorites.some((song) => song.trackid === _songObj.trackid)) {
        _state.mySongs.favorites.push(_songObj);
      }
    },
    REMOVE_SONG_FROM_FAVORITES(_state, _songId) {
      const filtered = [..._state.mySongs.favorites].filter((song) => song.trackid !== _songId);
      _state.mySongs.favorites = filtered;
    },
    TOGGLE_SONG_RATING(_state, { trackid, rating }) {
      if (_state.mySongs.rated.some((song) => song.trackid === trackid)) {
        const myRated = [..._state.mySongs.rated];
        const ratedIndex = myRated.findIndex((song) => song.trackid === trackid);
        if (ratedIndex !== -1) {
          if (myRated[ratedIndex].rating !== rating) {
            myRated[ratedIndex].rating = rating;
            _state.mySongs.rated = myRated;
          }
        }
      } else {
        _state.mySongs.rated.push({ trackid, rating });
      }
    },
    REMOVE_SONG_FROM_RATED(_state, { trackid }) {
      const filtered = [..._state.mySongs.rated].filter((song) => song.trackid !== trackid);
      _state.mySongs.rated = filtered;
    },
    SET_LOGIN_MESSAGE(_state, _value) {
      _state.loginMessage = _value;
    }
  },
  getters: {
    trailerIds: () => {
      // const integerIds = range(23912, 23930);
      const integerIds = [
        9258, 9257, 9256, 9255, 9254, 9253, 9252, 9251, 9250, 9248, 9247, 9246, 9245, 9244, 9243,
        9242, 9249, 9241,
      ];

      return integerIds.map(String);
    },
    itemImg: (state) => (songObj) => {
      // below url is cloudfront CDN for gwrAlbumArt/newAlbumArt/
      // v1 = const url = "https://d1x5fkly1n3m8k.cloudfront.net/";
      //v1.5 = const url = "https://d1m1roizv6s1qz.cloudfront.net/";
      if (!!state.cdnUrl.length) {
        if (state.loadingSongInfo) {
          return "https://gracewayradio.com/artwork/loading.gif";
        }
        if (songObj?.picture) {
          return state.cdnUrl + songObj.picture + ".jpg";
        }
        return "https://gracewayradio.com/artwork/customMissing.jpg";
      } else {
        // TODO => fire alert if CDN URL is empty or enters this else block...
        return "https://gracewayradio.com/artwork/customMissing.jpg";
      }
    },
  },
  actions: {
    setQueue({ commit }, value) {
      commit("ADD_SONG_TO_QUEUE", value);
    },
    viewSongInfo({ commit }, songObj) {
      commit("SET_ACTIVE_SONG", songObj);
    },
    async login({ commit }, payload) {
      const { username, password } = payload;
      const response = await axios.post(`${apiURL}/api/admin/login`, { username, password })
      if (response.status === 200) {
        localStorage.setItem('authToken', JSON.stringify(response.data));
        commit("SET_LOGIN_MESSAGE", response.data?.message || "You are now logged in!")
      } else {
        commit("SET_LOGIN_MESSAGE", response.data?.message || "Credentials do not match.")
      }
    },
    async logout({ commit }) {
      commit("CLEAR_AUTHORIZATION");
    },
    setFetchInterval({ commit }, value) {
      commit("SET_FETCH_INTERVAL", value);
    },
    getQueueItem({ commit }) {
      const url = `${apiURL}/api/queueItem`;
      axios
        .get(url)
        .then((res) => {
          if (res?.data) {
            const responseObject = Object.assign({}, ...res.data);
            commit("ADD_SONG_TO_QUEUE", responseObject);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCdnUrl({ commit }) {
      const imageUrl = `${apiURL}/api/imageurl`;
      try {
        const req = await axios.post(imageUrl);
        const { url } = req.data;
        commit("SET_CDN_URL", url);
      } catch (err) {
        console.log(err);
      }
    },
    async getSongInfo({ commit, state }, payload) {
      const url = `${apiURL}/api/info`;
      
      try {
        let data = payload;
        
        if (!data?.currentTrack) {
          commit("SET_SONG_LOADING", true);
          const req = await axios.post(url);

          if (req.data) data = req.data;
          else throw new Error("Could Not Retrieve Info From Server");
        }
        
        const { currentTrack, history, queue } = data?.[0] || data || {};
        
        if (history) commit("SET_SONG_HISTORY", history);
        if (queue) commit("SET_SONG_QUEUE", queue);
        if (!state.songInfo?.trackid || currentTrack?.trackid !== state.songInfo.trackid) commit("SET_SONG_OBJECT", currentTrack);
      } catch (err) {
        console.error(err);
      } finally {
        commit("SET_SONG_LOADING", false);
      }
    },
    toggleFavorite({ state, commit }, songObj) {
      const { trackid } = songObj;
      const url = `${apiURL}/api/song`;
      commit("SET_FEEDBACK_LOADING", true);
      if (state.mySongs.favorites.some((song) => song.trackid === trackid)) {
        // TODO: stop doing trackid.trackid
        axios
          .post(url, { trackid: { trackid }, feedback: { unfavorite: true } })
          .then((res) => {
            if (res?.data?.trackid) {
              commit("REMOVE_SONG_FROM_FAVORITES", trackid);
            } else if (res?.data?.message) {
              console.error(res.data.message);
            }
          })
          .catch((err) => {
            console.error(err.response);
          })
          .finally(() => {
            commit("SET_FEEDBACK_LOADING", false);
          });
      } else {
        const mapped = {
          trackid: songObj.trackid,
          title: songObj.title,
          artist: songObj.artist,
          album: songObj.album,
          picture: songObj.picture,
        };
        axios
          .post(url, { trackid: { trackid }, feedback: { favorite: true } })
          .then((res) => {
            if (res?.data?.trackid) {
              commit("ADD_SONG_TO_FAVORITES", mapped);
            } else if (res?.data?.message) {
              console.error(res.data.message);
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            commit("SET_FEEDBACK_LOADING", false);
          });
      }
    },
    toggleRated({ commit, state, rootGetters }, { songObj, rating }) {
      const ratedObj = { trackid: songObj.trackid, rating };
      const trackid = { trackid: songObj.trackid };
      const url = `${apiURL}/api/song`;

      const songIdx = state.mySongs.rated.findIndex((song) => song.trackid === songObj.trackid);
      let feedback = {};
      let currentRating = "";
      commit("SET_FEEDBACK_LOADING", true);
      if (songIdx !== -1) {
        currentRating = state.mySongs.rated[songIdx].rating; // ('liked'/'disliked')
        if (rating === "liked") {
          if (currentRating === "liked") {
            feedback = { unlike: true };
          } else {
            feedback = { undislike: true, like: true };
          }
        } else if (rating === "disliked") {
          if (currentRating === "disliked") {
            feedback = { undislike: true };
          } else {
            feedback = { unlike: true, dislike: true };
          }
        }
      } else if (rating === "liked") {
        feedback = { like: true };
      } else {
        feedback = { dislike: true };
      }
      let headerObj = {};
      if (rootGetters['admin/authToken'].length > 10 ) {
        headerObj = { headers: { authorization: rootGetters['admin/authToken'] } };
      }
      if (Object.keys(feedback).length) {
        axios
          .post(url, { trackid, feedback }, headerObj)
          .then((res) => {
            if (res?.data?.trackid) {
              if (currentRating && currentRating === rating) {
                commit("REMOVE_SONG_FROM_RATED", ratedObj);
              } else {
                commit("TOGGLE_SONG_RATING", ratedObj);
              }
            } else if (res?.data?.message) {
              console.error(res.data.message);
              // show snackbar, maybe? 'Server error, unable to set like/dislike right now'
            }
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            commit("SET_FEEDBACK_LOADING", false);
          });
      }
    },
    checkRequestService({ state, commit }) {
      const url = `${apiURL}/api/checkRequestService`;
      axios
        .get(url)
        .then((res) => {
          // eslint-disable-next-line no-prototype-builtins
          if (
            res?.data &&
            typeof res.data === "object" &&
            // eslint-disable-next-line no-prototype-builtins
            res.data.hasOwnProperty("serviceStatus")
          ) {
            const isOnline = res.data.serviceStatus;
            if (typeof isOnline === "boolean" && state.requestServiceIsDown === isOnline) {
              commit("SET_REQUEST_SERVICE_IS_DOWN", !isOnline);
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    saveSermonTimes({ commit }, sermonTimes) {
      commit("SET_SERMON_TIMES", sermonTimes);

      localStorage.setItem("sermonProgress", JSON.stringify(sermonTimes));
    },
  },
});
