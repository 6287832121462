<template>
  <div class="text-center">
    <v-dialog v-model="audioErrorDialog.open" width="500">
      <v-card>
        <v-card-title>
          We're sorry, there seems to be a problem
        </v-card-title>

        <v-card-text v-if="firstFail">
          <div>Would You Like To Try Changing Streams?</div>
          <div>
            <v-btn @click="changeQuality" block dark color="blue lighten-2">Change Quality</v-btn>
          </div>
        </v-card-text>

        <v-card-text v-else>
          <div>Our Streams Are Down, Please Nofity Us of The Problem.</div>
          <v-divider></v-divider>
          <v-form ref="form">
            <v-radio-group v-model="audioErrorDialog">
              <v-radio
                v-for="(x, i) in audioErrorDialog.options"
                :key="i"
                :label="x"
                :value="x"
              ></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-if="!firstFail">
          <v-spacer></v-spacer>
          <v-btn color="primary" dark @click="audioErrorDialog.open = false" type="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "StreamDownDialog",
  data: () => ({
    firstFail: true
  }),
  watch: {
    audioErrorDialog(state) {
      if (state === "open") this.audioErrorDialog.open = false;
    }
  },
  methods: {
    changeQuality() {
      let quality = "lq";
      if (!this.$store.state.audio.quality === "hq") quality = "hq";
      this.$store.dispatch("audio/setQuality", quality);
      this.audioErrorDialog.open = false;
      this.firstFail = false;
    }
  },
  computed: {
    audioErrorDialog() {
      return this.$store.state.audio.audioErrorDialog;
    }
  }
};
</script>
