const analytics = {
  namespaced: true,
  state: {
    siteStartTime: new Date(),
    listeningLog: [],
    pageLog: [],
  },
  getters: {
    currentSongInfo: (state, getters, rootState) => {
      const { songid = 'unknown', album = 'unknown', artist = 'unknown', title = 'unknown' }  = rootState.songInfo;
      return { songId: songid, album, artist, title };
    },
    msToTime: () => (ms) => {
      let seconds = (ms / 1000).toFixed(1);
      let minutes = (ms / (1000 * 60)).toFixed(1);
      let hours = (ms / (1000 * 60 * 60)).toFixed(1);
      let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
      if (seconds < 60) return seconds + " Sec";
      else if (minutes < 60) return minutes + " Min";
      else if (hours < 24) return hours + " Hrs";
      else return days + " Days"
    },
    prettyDate: () => (date) => {
      const newDate = new Date(date);
      return newDate.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute:'2-digit' });
    },
    userActivityLog: (state, getters, rootState) => (endTime) => {
      return {
        uuid: rootState.userId || 'anonymous',
        referrer: document.referrer || 'unknown',
        startTime: getters.prettyDate(state.siteStartTime),
        endTime: getters.prettyDate(endTime),
        uglyTime: endTime,
        elapsed: getters.msToTime(endTime - state.siteStartTime),
        listeningLog: state.listeningLog,
        pageLog: state.pageLog,
      };
    },
  },
  mutations: {
    LOG_LISTENING(_state, _newListeningArr) {
      _state.listeningLog = _newListeningArr;
    },
    LOG_ROUTE_INFO(_state, _newPageArr) {
      _state.pageLog = _newPageArr;
    },
  },
  actions: {
    updatePageLog({ commit, state, getters }, { page }) {
      const existingLog = [...state.pageLog];
      const newLogIndex = existingLog.length;
      const prevLogRecord = existingLog[newLogIndex - 1];
      const newDate = new Date();
      const newLogRecord = {
        order: newLogIndex + 1,
        time: newDate,
        prettyDate: getters.prettyDate(newDate),
        page,
      }
      if (prevLogRecord?.time) {
        prevLogRecord['timeOnPage'] = getters.msToTime(newLogRecord.time - prevLogRecord.time);
      }
      existingLog.push(newLogRecord);
      commit('LOG_ROUTE_INFO', existingLog);
    },
    updateListeningLog({ commit, state, rootState, getters }, { action, message }) {
      const existingLog = [...state.listeningLog];
      const newLogIndex = existingLog.length;
      const prevLogRecord = existingLog[newLogIndex - 1];
      const newDate = new Date();
      const newLogRecord = {
        order: newLogIndex + 1,
        time: newDate,
        prettyDate: getters.prettyDate(newDate),
        action,
        ...(message ?
          { weeklyMessage: message.title } :
          { radioSong: { ...getters.currentSongInfo } }),
      }
      if (action === 'pause' && prevLogRecord?.action === 'play') {
        newLogRecord['elapsed'] = getters.msToTime(newLogRecord.time - prevLogRecord.time);
      }
      existingLog.push(newLogRecord);
      commit('LOG_LISTENING', existingLog);
    },
  }
};

export default analytics;
