import axios from "axios";
import Vue from "vue";

const audio = {
  namespaced: true,
  state: {
    audioId: "radio",
    // AUDIO STREAM
    quality: "hq",
    hqStream: "https://us1.streamingpulse.com/ssl/gwrv2-128",
    mqStream: "https://us1.streamingpulse.com/ssl/gwrv2-64",
    lqStream: "https://us1.streamingpulse.com/ssl/gwrv2",
    // hqStream: "https://us3.streamingpulse.com/ssl/graceway_pulse",
    // lqStream: "https://us1.streamingpulse.com/ssl/graceway_64",
    audioIsPlaying: false,
    audioLoading: false,
    audioErrorDialog: {
      open: false,
      details: "",
      sendMessage: false,
      options: null,
      stream: "",
    },
    // -------------------------------------------------------
    // WEEKLY MESSAGES
    weeklyMessage: [],
    selectedMessage: [],
    allMessageTitles: [],
    messageAudio: "",
    // ----------------------------------------------------
    // AUDIO OBJECT
    audioObject: null,
    audioSeek: 0,
    // --------------------------------------------------------
  },
  mutations: {
    SET_MESSAGE_AUDIO(_state, _audio) {
      _state.messageAudio = _audio;
    },
    SET_STREAM_QUALITY(_state, _quality) {
      _state.quality = _quality;
    },
    SET_AUDIO_ERROR_DIALOG(_state, _dialog) {
      _state.audioErrorDialog = _dialog;
    },
    AUDIO_LOADING(_state, _loading) {
      _state.audioLoading = _loading;
    },
    SET_AUDIO_IS_PLAYING(_state, _playing) {
      _state.audioIsPlaying = _playing;
    },
    SET_WEEKLY_MESSAGE(_state, _message) {
      _state.weeklyMessage = _message;
    },
    SET_SELECTED_MESSAGE(_state, _message) {
      _state.selectedMessage = _message;
    },
    SET_ALL_MESSAGE_TITLES(_state, _titles) {
      _state.allMessageTitles = _titles;
    },
    SET_AUDIO_SEEK(_state, _seek) {
      _state.audioSeek = _seek;
    },
    SET_AUDIO_OBJECT(_state, _audio) {
      _state.audioObject = _audio;
    },
  },
  getters: {
    stream(state) {
      if (state.quality === "hq") {
        return state.hqStream;
      } else if (state.quality === "mq") {
        return state.mqStream;
      } else {
        return state.lqStream;
      }
    },
  },
  actions: {
    setAudioSeek({ commit }, newValue) {
      const audioEl = document.getElementById("audio");
      const value = audioEl.currentTime;
      if (!isNaN(newValue)) audioEl.currentTime = newValue;
      commit("SET_AUDIO_SEEK", !isNaN(newValue) ? newValue : value);
    },
    setQuality({ commit, dispatch }, quality) {
      dispatch("audioStop");
      commit("SET_STREAM_QUALITY", quality);
      Vue.nextTick(() => {
        dispatch("audioPlay");
      });
    },
    createAudio({ commit }, { audio, location }) {
      /* errors returned by onloaderror/onplayerror
      1 - The fetching process for the media resource was aborted by the user agent at the user's request.
      2 - A network error of some description caused the user agent to stop fetching the media resource, after the resource was established to be usable.
      3 - An error of some description occurred while decoding the media resource, after the resource was established to be usable.
      4 - The media resource indicated by the src attribute or assigned media provider object was not suitable.
      */
      // const errorMessages = {
      //   1: "Audio Aborted by user agent",
      //   2: "Network Error caused by user agent",
      //   3: "Resource decoding Error",
      //   4: "Media Source not Suitable",
      // };

      // let newAudio = new Audio(
      //   audio
      // {
      // src: [audio],
      // buffer: true,
      // preload: "false",
      // autoplay: false,
      // html5: true,
      // format: ["mp3"],
      // onloaderror: (id, err) => {
      //   dispatch("setQuality", state.quality === "hq" ? "mq" : "lq": "hq");
      //   console.log("on Load Error: ", id, errorMessages[err]);
      // },
      // onplayerror: (id, err) => {
      //   dispatch("setQuality", state.quality === "hq" ? "lq" : "hq");
      //   console.log("on play error: ", id, errorMessages[err]);
      // },
      // onplay: (id) => {
      //   console.log("playing id: ", id);
      // },
      // onpause: (id) => {
      //   console.log("pausing id: ", id);
      // },
      // onstop: (id) => {
      //   console.log("stopped id: ", id);
      // },
      // onseek: (id) => {
      //   // do stuff
      // },
      // onend: (id) => {
      //   console.log("on end:", id);
      // },
      // }
      // );
      const audioEl = document.getElementById("audio");
      audioEl.src = audio;
      // TODO: commit to update state.audioId
      commit(`${location}`, audioEl);
    },
    // audioPlay({ state, commit, dispatch }) {
    //   if (!state.audioPlaying) {
    //     commit("SET_MESSAGE_AUDIO", state.audioObject?._src);
    //     state.audioObject?.play();
    //     commit("SET_AUDIO_PLAYING", true);
    //   }
    // },
    // audioStop({ state, commit, dispatch }) {
    //   state.audioObject?.stop();
    //   commit("SET_AUDIO_PLAYING", false);
    //   dispatch("setAudioSeek", 0);
    // },
    clearAudioObject({ commit }) {
      commit("SET_AUDIO_OBJECT", null);
      Vue.nextTick(() => commit("SET_AUDIO_SEEK", 0));
    },
    async audioPlay(
      { commit, getters, dispatch },
      { src, seek } = { src: getters.stream, seek: 0 }
    ) {
      // try catch
      src = src || getters.stream;
      const isRadio = src?.includes("streamingpulse.com"); // do this better
      commit("AUDIO_LOADING", true);
      if (isRadio) dispatch("clearAudioObject");
      const audioEl = document.getElementById("audio");
      if (!audioEl.src || audioEl.src !== src) {
        audioEl.src = src;
        audioEl.currentTime = seek;
      }
      audioEl.play().then(() => {
        if (!isNaN(audioEl.duration) && audioEl.duration !== Infinity) {
          commit("SET_AUDIO_OBJECT", { duration: audioEl.duration });
        }

        commit("AUDIO_LOADING", false);
        commit("SET_AUDIO_IS_PLAYING", true);
      });
    },
    audioPause({ commit }) {
      const audioEl = document.getElementById("audio");
      audioEl.pause();
      commit("SET_AUDIO_IS_PLAYING", false);
    },
    audioStop({ commit, state }) {
      const audioEl = document.getElementById("audio");
      audioEl.pause();
      // TODO check if message playing
      if (state.audioObject) commit("SET_AUDIO_SEEK", 0);
      audioEl.currentTime = 0; // effectively 'stop' instead of 'pause'
      commit("SET_AUDIO_IS_PLAYING", false);
    },
    audioPlayToggle({ dispatch, state, getters }, src = getters.stream) {
      const audioEl = document.getElementById("audio");
      audioEl?.paused
        ? dispatch("audioPlay", { src, seek: 0 })
        : !!state.audioObject
        ? dispatch("audioPause")
        : dispatch("audioStop");
    },
    getCurrentMessage({ commit }) {
      axios
        // .get("http://127.0.0.1:8000/messageofweek/current_message")
        .get("https://gwrapi.herokuapp.com/messageofweek/current_message")
        .then((res) => {
          if (res?.data && res?.data.title) {
            commit("SET_WEEKLY_MESSAGE", res.data);
          } else {
            console.error("Unable to fetch weekly message");
          }
        })
        .catch((err) => {
          console.error("Something went wrong: ", err);
        });
    },
    getSelectedMessage({ commit }, slug) {
      const url = `https://gwrapi.herokuapp.com/messageofweek?title=${slug}`;
      axios
        .get(url)
        .then((res) => {
          if (res?.data && Array.isArray(res?.data)) {
            const messageData = Object.assign({}, ...res.data);
            commit("SET_SELECTED_MESSAGE", messageData);
          } else {
            console.error(`Unable to find message with slug ${slug}`);
          }
        })
        .catch((err) => {
          console.log("Something went wrong: ", err);
        });
    },
    getAllMessageTitles({ commit }) {
      axios
        .get("https://gwrapi.herokuapp.com/messageofweek/past_titles")
        .then((res) => {
          if (res?.data && Array.isArray(res?.data)) {
            commit("SET_ALL_MESSAGE_TITLES", res.data);
          } else {
            console.error("Cannot fetch titles");
          }
        })
        .catch((err) => {
          console.error("Something went wrong: ", err);
        });
    },
  },
};

export default audio;
