import axios from "axios";

const blog = {
  namespaced: true,
  state: {
    selectedBlog: null,
    allBlogs: null,
  },
  mutations: {
    SET_BLOG(_state, _blog) {
      _state.selectedBlog = _blog;
    },
    SET_ALL_BLOGS(_state, _blogs) {
      _state.allBlogs = _blogs;
    }
  },
  actions: {
    setChosenBlog({ commit }, slug) {
      const url = "https://gwrapi.herokuapp.com/blogs"
      const searchParam = url + "?slug=" + `${slug}`
      axios
        .get(searchParam)
        .then(res => {
          if (res?.data && Array.isArray(res?.data) && res?.data?.length === 1) {
            const foundResult = res?.data[0].url;
            axios.get(foundResult).then((res) => {
              if (typeof res?.data === 'object' && res?.data?.id) {
                commit('SET_BLOG', res?.data)
              }
            })
            .catch(err => {
              console.error("Could not resolve foundBlog url: ", err)
            })
          }
        })
        .catch(err => {
          console.error("Unable to set chosen blog in store: ", err);
        });
    },
    getBlogs({ commit }) {
      axios
        .get("https://gwrapi.herokuapp.com/blogs")
        .then(res => {
          if (res?.data && Array.isArray(res?.data)) {
            commit("SET_ALL_BLOGS", res?.data);
          }
        })
        .catch(err => {
          console.error("Cannot Retrieve Blogs");
        });
    },
    clearChosenBlog({ commit }) {
      commit("SET_BLOG", null);
    }
  }
};

export default blog;
