import axios from "axios";
import apiURL from "../../config/urlconfig";

const admin = {
  namespaced: true,
  state: {
    response: [],
    artworkLoading: false,
    deleteResponse: null,
    artistQuery: "",
    results: [],
    reportList: [],
  },
  mutations: {
    SET_REPORT_LIST(_state, _value) {
      _state.reportList = _value;
    },
    SET_ARTWORK_LOADING(_state, _value) {
      _state.artworkLoading = _value;
    },
    SET_RESPONSE(_state, _value) {
      _state.response = _value;
    },
    SET_DELETE_RESPONSE(_state, _value) {
      _state.deleteResponse = _value;
    },
    SET_ARTIST_QUERY(_state, _value) {
      _state.artistQuery = _value;
    },
    SET_FEATURED_RESULTS(_state, _value) {
      _state.results = _value;
    },
  },
  getters: {
    authToken: () => {
      try {
        const { token } = JSON.parse(localStorage.getItem("authToken"));
        return `Bearer ${token}`;
      } catch (e) {
        console.error(e);
        return "";
      }
    },
  },
  actions: {
    setArtistQuery({ commit }, value) {
      commit("SET_ARTIST_QUERY", value);
    },
    async getTracks({ commit, getters }) {
      commit("SET_ARTWORK_LOADING", true);
      const url = `${apiURL}/api/admin/artwork`;
      try {
        const response = await axios.post(
          url,
          {},
          { headers: { authorization: getters.authToken } }
        );
        commit("SET_RESPONSE", response.data);
      } catch (e) {
        console.error(e);
      }

      commit("SET_ARTWORK_LOADING", false);
    },
    async deleteSong({ commit, getters }, trackid) {
      const url = `${apiURL}/api/admin/deletetrack`;
      let message = "Unable to delete track";
      try {
        const response = await axios.post(
          url,
          { trackid },
          { headers: { authorization: getters.authToken } }
        );
        if (response && response.data.message) {
          commit("SET_DELETE_RESPONSE", response.data);
          message = response.data.message;
        }
      } catch (err) {
        console.log(err);
      } finally {
        return message;
      }
    },
    async skipTrack({ commit, getters }) {
      const url = `${apiURL}/api/admin/skip`;

      let message = "Unable to skip track";

      try {
        const { data } = await axios.post(
          url,
          {},
          { headers: { authorization: getters.authToken } }
        );

        if (data.success) message = "Track skipped, give it a moment";
      } catch (err) {
        console.log("skipTrack err", err);
      } finally {
        return message;
      }
    },
    async getFeaturedArtist({ commit, getters }, artist) {
      const url = `${apiURL}/api/admin/featuredartist`;

      try {
        const response = await axios.post(
          url,
          { artist },
          { headers: { authorization: getters.authToken } }
        );
        commit("SET_ARTIST_QUERY", "");
        alert(response?.data?.message || "no dice");
        if (!!response?.data?.message) commit("SET_FEATURED_RESULTS", []);
      } catch (error) {
        console.log(error);
      }
    },
    async searchForArtist({ commit, state, getters }) {
      const url = `${apiURL}/api/admin/queryartists`;
      try {
        const response = await axios.post(
          url,
          { artistQuery: state.artistQuery },
          { headers: { authorization: getters.authToken } }
        );
        if (response?.data?.artists) commit("SET_FEATURED_RESULTS", response.data.artists);
      } catch (error) {
        console.log(error);
      }
    },
    async getReportList({ commit, getters }) {
      const url = `${apiURL}/api/admin/listreports`;
      try {
        const response = await axios.post(
          url,
          {},
          { headers: { authorization: getters.authToken } }
        );
        if (response.data && Array.isArray(response.data)) {
          commit("SET_REPORT_LIST", response.data);
        } else {
          return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async downloadReport({ getters }, payload) {
      const url = `${apiURL}/api/admin/downloadreport`;
      const { filename } = payload;
      try {
        const response = await axios.post(
          url,
          { filename },
          { headers: { authorization: getters.authToken }, responseType: "blob" }
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `${filename}`);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default admin;
