<template>
  <v-footer app height="160" padless>
    <v-expand-transition v-if="$route.name !== 'FullPagePlayer'">
      <div
        style="position: relative; background-color: #0d1755; width: 100%; height: 100%"
        class="d-flex flex-column"
      >
        <v-icon class="fader" style="position: absolute; left: 50%" dark @click="swipeUp()"
          >mdi-chevron-double-up</v-icon
        >
        <v-row align="center" class="pa-0" no-gutters>
          <v-col class="pa-0" sm="2">
            <img
              contain
              :src="itemImg"
              style="width: 80%; cursor: pointer"
              @click="viewSongInfo(songInfo)"
            />
          </v-col>
          <v-col col="auto" class="px-0" align="center">
            <v-responsive :max-width="$vuetify.breakpoint.xs ? '170px' : '600px'">
              <div class="marquee-container">
                <div
                  class="d-block text-sm-title white--text"
                  :class="[marqueeTrigger(songInfo, 'title', 16) ? 'marquee' : '']"
                >
                  {{ songInfo && songInfo.title ? songInfo.title : "" }}
                </div>
                <div
                  class="d-block mt-n1 text-sm-subtitle-1 caption white--text"
                  :class="[marqueeTrigger(songInfo, 'artist', 18) ? 'marquee' : '']"
                >
                  {{ songInfo && songInfo.artist ? songInfo.artist : "" | separate }}
                </div>
              </div>
              <v-chip
                elevation="3"
                :style="$route.name === 'Requests' ? 'display:none' : ''"
                shaped
                label
                dark
                color="green"
                outlined
                to="requests"
              >
                <v-icon dark class="pr-2">mdi-bullhorn-outline</v-icon>Request A Song</v-chip
              >
            </v-responsive>
          </v-col>

          <v-col col="1" class="text-center">
            <radio-quality-fab-button
              :btnAbsolute="true"
              :btnXsmall="true"
              btnStyle="top:-16px;right:10px;"
            />
            <v-btn
              icon
              @click="$emit('play-pause')"
              dark
              :loading="radioLoading"
              height="60px"
              width="60px"
            >
              <v-icon v-if="!audioIsPlaying" size="60px">mdi-play</v-icon>
              <v-icon size="60px" v-else>mdi-pause</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-footer>
</template>

<script>
import RadioQualityFabButton from "./RadioQualityFabButton.vue";

export default {
  name: "player",
  components: { RadioQualityFabButton },
  computed: {
    radioLoading() {
      return this.$store.state.audio.radioLoading;
    },
    songInfo() {
      return this.$store.state.songInfo;
    },
    audioIsPlaying() {
      return this.$store.state.audio.audioIsPlaying;
    },
    itemImg() {
      return this.$store.getters.itemImg(this.songInfo);
    },
  },
  methods: {
    viewSongInfo(songObj) {
      this.$store.dispatch("viewSongInfo", songObj);
    },
    swipeUp() {
      this.$router.push({ name: "FullPagePlayer" });
    },
    marqueeTrigger(el, att, val) {
      return !!(el && el[att] && el[att].length > val);
    },
  },
};
</script>

<style lang="scss" scoped>
// @import url("https://fonts.googleapis.com/css2?family=Overlock&display=swap");
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.fader {
  animation: fade 5s ease-in-out infinite;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-130%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-130%);
  }
}
// width: 200px;
.marquee-container {
  font-family: "Overlock";
  font-size: 14px;
  padding-left: 0.5rem;
}
.marquee {
  white-space: nowrap;
  animation: marquee 8s linear infinite;
  -webkit-animation: marquee 8s linear infinite;
}
.marquee:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
</style>
