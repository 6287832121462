<template>
  <v-navigation-drawer app style="z-index: 4; padding-top: 60px; overflow-y: hidden" width="300" v-model="isOpen">
    <div style="position: sticky; top: 0; background-color: #fff; z-index: 25">
      <div class="album-art" style="position: relative">
        <radio-quality-fab-button :btn-absolute="true" btnStyle="top:5px; left:80%" />
        <v-img @click="viewSongInfo(songInfo)" style="cursor:pointer" width="auto" contain height="300" :src="itemImg"
          :alt="songInfo && songInfo.title ? songInfo.title : 'Graceway Radio'"
          onerror="this.src='https://gracewayradio.com/artwork/customMissing.jpg'">
        </v-img>
        <v-btn fab large color="grey lighten-1" bottom :loading="audioLoading" right absolute
          @click="$emit('play-pause')">
          <v-icon large v-if="!audioIsPlaying">mdi-play</v-icon>
          <v-icon large v-else>mdi-pause</v-icon>
        </v-btn>
      </div>
      <div class="song-meta white--text">
        <div class="text-h6 marquee-container">
          <div :class="[marqueeTrigger(songInfo, 'title', 18) ? 'marquee' : '']">
            {{ songInfo && songInfo.title ? songInfo.title : "" }}
          </div>
        </div>
        <div class="text-body-2 marquee-container">
          <div :class="[marqueeTrigger(songInfo, 'artist', 36) ? 'marquee' : '']">
            {{ songInfo && songInfo.artist ? songInfo.artist : "" | separate }}
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-y: auto; max-height: calc(100vh - 365px)">
      <history-queue />
      <v-btn color="grey darken-2" class="white--text" large fab absolute right style="bottom: 10px"
        @click="isOpen = false">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import HistoryQueue from "../songInfo/HistoryQueue.vue";
import RadioQualityFabButton from "./RadioQualityFabButton.vue";

export default {
  name: "SidePlayer",
  data: () => ({
    historyUpcoming: "upcoming",
  }),
  props: {
    openPlayer: {
      type: Boolean,
      default: false,
    },
  },
  components: { HistoryQueue, RadioQualityFabButton },
  computed: {
    audioLoading() {
      return this.$store.state.audio.audioLoading;
    },
    isOpen: {
      get() {
        return this.openPlayer;
      },
      set(val) {
        if (!val) {
          this.$emit("close-player");
        }
      },
    },
    audioIsPlaying() {
      return this.$store.state.audio.audioIsPlaying;
    },
    loadingSongInfo() {
      return this.$store.state.loadingSongInfo;
    },
    songInfo() {
      return this.$store.state.songInfo;
    },
    itemImg() {
      return this.$store.getters.itemImg(this.songInfo);
    },
  },
  methods: {
    viewSongInfo(songObj) {
      this.$store.dispatch("viewSongInfo", songObj);
    },
    marqueeTrigger(el, att, val) {
      return !!(el && el[att] && el[att].length > val);
    },
  },
};
</script>

<style>
.v-navigation-drawer__content {
  overflow: hidden;
}

.song-meta {
  padding-top: 5px;
  padding-left: 5px;
  height: 65px;
  background-color: dodgerblue;
}

.album-art {
  max-height: 300px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-130%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-130%);
  }
}

.marquee-container {
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  animation: marquee 8s linear infinite;
  -webkit-animation: marquee 8s linear infinite;
}

.marquee:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
</style>
