<template>
  <div class="snowflakes" aria-hidden="true">
    <div v-for="(num, i) in numberOfSnowflakes" class="snowflake" :key="num" :style="{ left: snowflakeLeft(i), animationDelay: snowflakeAnimationDelay() }">
      {{ num % 2 === 0 ? "❅" : "❆" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfSnowflakes: {
      type: Number,
      default: 12,
    },
  },
  name: "SnowFallOverlay",
  methods: {
    snowflakeLeft(index) {
      return `${Math.max((100 / this.numberOfSnowflakes) * index, 1)}%`
    },
    snowflakeAnimationDelay() {
      const delay1Multiplier = Math.min(Math.max(16, this.numberOfSnowflakes / 2), 40);
      const delay2Multiplier = Math.min(Math.max(6, this.numberOfSnowflakes), 16);
      const animationDelay1 = Math.round(Math.random() * delay1Multiplier * 8) / 8;
      const animationDelay2 = Math.round(Math.random() * Math.min(animationDelay1, delay2Multiplier) * 8) / 8;
      return `${animationDelay1}s, ${animationDelay2}s`;
    },
  },
};
</script>

<style scoped>
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }

  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }

  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {

  0%,
  100% {
    transform: translateX(0)
  }

  50% {
    transform: translateX(80px)
  }
}

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}
</style>
