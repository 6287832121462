<template>
  <v-app :style="$route.name === 'FullPagePlayer' ? 'background-color:gray' : ''">
    <snow-fall-overlay v-if="isChristmas" :number-of-snowflakes="isMobile ? 18 : 60" />
    <audio id="audio" :src="stream" preload="none">
      Your browser does not support the audio element.
    </audio>
    <stream-down-dialog />
    <v-dialog v-model="openSongInfo" width="fit-content">
      <song-info-card :is-christmas="isChristmas" :is-admin="isAdmin" />
    </v-dialog>
    <v-app-bar elevate-on-scroll app :color="$vuetify.theme.dark ? '' : 'white'" clipped-left>
      <div class="d-flex align-center">
        <v-img
          style="cursor: pointer"
          alt="Graceway Radio Logo"
          contain
          max-height="60"
          max-width="60"
          :class="isMobile ? '' : 'ml-2'"
          :src="gwrLogo"
          @click="navigateHome"
        />
      </div>
      <v-toolbar-title class="ml-2" style="cursor: pointer" @click="navigateHome">
        <div :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-body-1'">Graceway Radio</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-if="!isMobile" class="d-flex align-center">
        <div>
          <v-btn
            v-for="navItem in navItems.filter((nav) => !nav.disabled)"
            :key="navItem.name"
            :text="!navItem.featured"
            active-class="active-button"
            class="mr-1"
            :color="navItem.featured ? 'warning' : ''"
            exact
            :small="$vuetify.breakpoint.lgAndUp"
            :x-small="$vuetify.breakpoint.mdAndDown"
            :to="navItem.link"
            >{{ navItem.name }}</v-btn
          >
        </div>
        <div class="ml-8 mt-4">
          <v-switch
            v-model="$vuetify.theme.dark"
            inset
            dense
            flat
            prepend-icon="mdi-weather-sunny"
            append-icon="mdi-moon-waxing-crescent"
          ></v-switch>
        </div>
      </div>

      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="openSideNav = !openSideNav"
      ></v-app-bar-nav-icon>
    </v-app-bar>
    <div v-if="!isMobile">
      <v-row>
        <v-btn
          color="primary"
          @click="openPlayer = true"
          elevation="0"
          tile
          style="
            transform: rotate(90deg);
            z-index: 3;
            position: fixed;
            left: -125px;
            top: 50%;
            width: 300px;
            height: 60px;
          "
        >
          <v-col cols="3" v-if="audioIsPlaying">
            <radio-quality-fab-button
              btnStyle="top:-20px;left:-8px;transform:rotate(-90deg)"
              :btnAbsolute="false"
            />
          </v-col>
          <v-col :cols="audioIsPlaying ? '5' : '6'">
            <div class="text-capitalize text-h3">Listen</div>
          </v-col>
          <v-col :cols="audioIsPlaying ? '4' : '3'">
            <v-btn
              fab
              small
              absolute
              :loading="audioLoading"
              style="transform: rotate(-90deg); top: 19px"
              @click.stop="openPlayerOnPushPlayButton()"
            >
              <v-icon v-if="!audioIsPlaying">mdi-play</v-icon>
              <v-icon v-else>mdi-pause</v-icon>
            </v-btn>
          </v-col>
        </v-btn>
      </v-row>
      <side-player
        @play-pause="playPause(false)"
        @close-player="openPlayer = false"
        :openPlayer="openPlayer"
      ></side-player>
    </div>
    <v-navigation-drawer v-model="openSideNav" right app temporary>
      <div class="d-flex align-center">
        <v-icon class="mr-2">mdi-weather-sunny</v-icon>
        <v-switch v-model="$vuetify.theme.dark" inset dense flat></v-switch>
        <v-icon class="ml-n2">mdi-moon-waxing-crescent</v-icon>
      </div>

      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
          <v-list-item
            v-for="navItem in navItems.filter((nav) => !nav.disabled)"
            :key="navItem.name"
          >
            <v-list-item-icon class="mr-0">
              <v-icon small>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="navTo(navItem.link)">{{ navItem.name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main :style="bgImage">
      <v-expand-transition>
        <router-view />
      </v-expand-transition>
      <c-footer v-if="$route.name !== 'About' && $route.name !== 'FullPagePlayer'" />
    </v-main>
    <v-expand-transition>
      <bottom-player
        v-if="isMobile && this.$route.name !== 'FullPagePlayer'"
        @toggle-player="fullSizePlayer = $event"
        @play-pause="playPause(false)"
      />
    </v-expand-transition>
  </v-app>
</template>

<script>
import SnowFallOverlay from "./components/SnowFallOverlay.vue";
import CFooter from "@/components/Footer.vue";
import BottomPlayer from "@/components/stream/BottomPlayer.vue";
import SongInfoCard from "@/components/songInfo/SongInfoCard.vue";
import SidePlayer from "./components/stream/SidePlayer.vue";
import RadioQualityFabButton from "./components/stream/RadioQualityFabButton.vue";
import StreamDownDialog from "./components/stream/StreamDownDialog.vue";

export default {
  name: "App",
  components: {
    CFooter,
    SnowFallOverlay,
    BottomPlayer,
    SongInfoCard,
    SidePlayer,
    RadioQualityFabButton,
    StreamDownDialog,
  },
  data: () => ({
    audio: undefined,
    interval: null,
    openSideNav: false,
    openPlayer: false,
    fullSizePlayer: false,
    navItems: [
      {
        name: "Request A Song",
        link: { name: "Requests" },
        featured: false,
        disabled: false,
        icon: "mdi-bullhorn-outline",
      },
      {
        name: "Content",
        link: { name: "FeaturedSpeakers" },
        featured: false,
        disabled: false,
        icon: "mdi-account-voice",
      },
      {
        name: "Schedule",
        link: { name: "ProgramSchedule" },
        featured: false,
        disabled: false,
        icon: "mdi-calendar-month-outline",
      },
      // {
      //   name: "Featured Sermon",
      //   link: { name: "Message" },
      //   featured: false,
      //   disabled: false,
      //   icon: "mdi-microphone-outline",
      // },
      {
        name: "Testimonies",
        link: { name: "Testimonies" },
        featured: false,
        disabled: false,
        icon: "mdi-lead-pencil",
      },
      // {
      //   name: 'About',
      //   link: { name: 'About' },
      //   featured: false,
      //   icon: 'mdi-map-marker-question-outline',
      // },
      {
        name: "Blog",
        link: { name: "Blogs" },
        featured: false,
        // disabled: true,
        icon: "mdi-newspaper-variant-outline",
      },
      {
        name: "Contact",
        link: { name: "Contact" },
        featured: false,
        disabled: true,
        icon: "mdi-email-send-outline",
      },
      // {
      //   name: "Links",
      //   link: { name: "HowToListen" },
      //   featured: false,
      //   disabled: false,
      //   icon: "mdi-link-variant",
      // },
      {
        name: "Donate",
        link: { name: "Donate" },
        featured: true,
        disabled: false,
        icon: "mdi-credit-card-outline",
      },
    ],
    // shouldBeShown: null,
  }),
  watch: {
    $route({ path }) {
      this.$store.dispatch("analytics/updatePageLog", { page: path });
      this.openPlayer = false;
      if (this.$route.name !== "Testimonies") {
        this.$vuetify.goTo(0);
      }
    },
    songInfo() {
      this.refreshSongInfo();
    },
    stream() {
      if (this.audioIsPlaying) this.playPause(true);
    },
  },
  mounted() {
    this.$store.dispatch("featureObjects/getFeatures");
    this.$store.dispatch("getCdnUrl");
    this.$store.dispatch("analytics/updatePageLog", { page: this.$route.path });
    this.initializeMeta();
    this.$store
      .dispatch("getSongInfo", null)
      .then((info) => this.setMediaMeta(info))
      .catch((err) => {
        console.error(err);
      });
    // this.socket.on("updateSongInfo", () => {
    //   this.refreshSongInfo();
    // });
  },
  methods: {
    sendLog() {
      const siteEndTime = new Date();
      const activityLog = this.$store.getters["analytics/userActivityLog"](siteEndTime);
    },
    openPlayerOnPushPlayButton() {
      this.openPlayer = true;
      this.playPause(false);
    },
    initializeMeta() {
      if ("mediaSession" in navigator) {
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: this.$store.state.songInfo?.title
            ? this.$store.state.songInfo.title
            : "Graceway Radio",
          artist: this.$store.state.songInfo?.artist
            ? this.$store.state.songInfo.artist
            : "CDM Ministries",
          album: this.$store.state.songInfo?.album
            ? this.$store.state.songInfo.album
            : "Cascade Chapel of Burlington, WA",
          artwork: this.$store.state.songInfo?.picture
            ? [{ src: this.$store.state.songInfo.picture, type: "image/jpeg" }]
            : [
                {
                  src: "https://gracewayradio.com/artwork/logo-96.jpg",
                  sizes: "96x96",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-128.jpg",
                  sizes: "128x128",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-192.jpg",
                  sizes: "192x192",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-256.jpg",
                  sizes: "256x256",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-384.jpg",
                  sizes: "384x384",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-512.jpg",
                  sizes: "512x512",
                  type: "image/jpeg",
                },
              ],
        });
        navigator.mediaSession.setActionHandler("play", () => this.playPause(false));
        navigator.mediaSession.setActionHandler("pause", () => this.playPause(false));
      }
    },
    setMediaMeta(info) {
      if ("mediaSession" in navigator) {
        const pictureBase = "https://d1m1roizv6s1qz.cloudfront.net/";
        navigator.mediaSession.metadata = new window.MediaMetadata({
          title: info?.title ? info.title : "Graceway Radio",
          artist: info?.artist ? info.artist : "CDM Ministries",
          album: info?.album ? info.album : "Cascade Chapel of Burlington, WA",
          // eslint-disable-next-line no-constant-condition
          artwork: info?.picture
            ? [{ src: pictureBase + info.picture + ".jpg", type: "image/jpeg" }]
            : [
                {
                  src: "https://gracewayradio.com/artwork/logo-96.jpg",
                  sizes: "96x96",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-128.jpg",
                  sizes: "128x128",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-192.jpg",
                  sizes: "192x192",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-256.jpg",
                  sizes: "256x256",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-384.jpg",
                  sizes: "384x384",
                  type: "image/jpeg",
                },
                {
                  src: "https://gracewayradio.com/artwork/logo-512.jpg",
                  sizes: "512x512",
                  type: "image/jpeg",
                },
              ],
        });
      }
    },
    async refreshSongInfo() {
      const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await timeout(13000);
      if (this.songInfo) {
        this.setMediaMeta(this.songInfo);
      }
    },
    playPause() {
      this.$store.dispatch("audio/audioPlayToggle");
    },
    navigateHome() {
      if (this.$route.name !== "Home") {
        this.$router.push({ name: "Home" });
      }
    },
    navTo(linkObj) {
      if (linkObj?.name && this.$route.name !== linkObj.name) {
        this.$router.push(linkObj);
      }
    },
  },
  computed: {
    // set in store, initialize false, fetch from BE
    isChristmas() {
      return !!Number(process.env.VUE_APP_IS_CHRISTMAS);
    },
    gwrLogo() {
      return this.isChristmas
        ? require("@/assets/logochristmas.png")
        : require("@/assets/logo.png");
    },
    isAdmin() {
      return !!this.$store.getters["admin/authToken"];
    },
    bgImage() {
      if (this.isChristmas) {
        return this.$vuetify.theme.dark
          ? {
              "background-image": `url(${require("@/assets/winter_night.jpg")})`,
              "background-position": "center center",
              "background-size": "cover",
              "background-repeat": "no-repeat",
            }
          : {
              "background-image": `url(${require("@/assets/winter.jpg")})`,
              "background-position": "center center",
              "background-size": "cover",
              "background-repeat": "no-repeat",
            };
      }

      return {};
    },
    songInfo() {
      return this.$store.state.songInfo;
    },
    audioLoading() {
      return this.$store.state.audio.audioLoading;
    },
    stream() {
      return this.$store.getters["audio/stream"];
    },
    hasFirstBeenPlayed() {
      return this.$store.state.audio.hasFirstBeenPlayed;
    },
    fetchInterval() {
      return this.$store.state.songFetchInterval;
    },
    openSongInfo: {
      get() {
        return !!this.$store.state?.activeSong;
      },
      set(val) {
        if (!val) {
          this.$store.dispatch("viewSongInfo", null);
          this.$store.dispatch("songObject/removeFetchedSong");
        }
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    audioIsPlaying() {
      return this.$store.state.audio.audioIsPlaying;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.sendLog);
  },
};
</script>

<style>
body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}
</style>

<style lang="scss" scoped>
.welcome-card {
  background-color: #323232;
  border: 2px solid #15cad5;
}
.active-button {
  color: blue;
}
.v-application {
  overflow-x: hidden !important;
}
.v-footer {
  overflow-x: visible !important;
}
</style>
