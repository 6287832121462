import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import socket from './socket';

Vue.prototype.$socket = socket;

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.filter('minutes', (value) => {
  if (!value || typeof value !== "number") return "00:00"
  let min = parseInt(value / 60),
      sec = parseInt(value % 60)
  min = min < 10 ? "0" + min : min
  sec = sec < 10 ? "0" + sec : sec
  value = min + ":" + sec
  return value
})

Vue.use(VueGtag, {
  config: { id: 'G-JL9E39S9D2' },
}, router);

Vue.config.productionTip = false;

Vue.filter('separate', (value) => {
  if (!value) return '';
  return value.split(';').join(', ');
});

// const uuidv4 = () => {
//   return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
//     (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//   );
// }

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.commit('INITIALIZE_STORE');
  },
  // created() {
  //   const newUuid = uuidv4();
  //   const userString = localStorage.getItem('user');
  //   let savedUuid = localStorage.getItem('uuid');
  //   if (!savedUuid) {
  //     localStorage.setItem('uuid', newUuid);
  //     savedUuid = newUuid;
  //   }
  //   this.$store.commit('SET_USER_ID', savedUuid);
  //   if (userString) {
  //     const userData = JSON.parse(userString);
  //     this.$store.commit('SET_USER_DATA', userData);
  //   }
  // },
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

store.subscribe((mutation, state) => {
  if (mutation.type === 'ADD_SONG_TO_FAVORITES'
  || mutation.type === 'REMOVE_SONG_FROM_FAVORITES'
  || mutation.type === 'TOGGLE_SONG_RATING'
  || mutation.type === 'REMOVE_SONG_FROM_RATED') {
    localStorage.setItem('savedSongs', JSON.stringify(state.mySongs));
  }
});
