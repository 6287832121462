import io from "socket.io-client";
import apiURL from "./config/urlconfig";

const socket = io(apiURL, {
  transports: ["websocket", "polling"],
  timeout: 10000,
  reconnectionAttempts: 50,
  autoConnect: true,
  withCredentials: true,
});

// socket.on("open", () => console.log("connected"));
socket.on("connect", () => {
  const { engine } = socket.io;
  // console.log("Current Socket Transport method: ", engine.transport.name); // in most cases, prints "polling"
  engine.once("upgrade", () => {
    // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
    console.log("Upgrade Fired, transport method: ", engine.transport.name); // in most cases, prints "websocket"
  });

  // engine.on("packet", ({ type, data }) => {
  //   console.log("packet received type: ", type);
  // });

  // engine.on("packetCreate", ({ type, data }) => {
  //   console.log("packed created type: ", type);
  // });

  // engine.on("drain", () => {
  //   console.log("Write buffer is drained...");
  // });

  engine.on("close", (reason) => {
    console.log("Socket Closed: ", reason);
  });
  // console.log("Socket Connected"); // true
});

socket.on("disconnect", (reason) => {
  switch (reason) {
    case "io server disconnect":
      console.log(reason, " => Attempting to manually reconnect: active = ", socket.active);
      socket.connect();
      break;
    case "parse error":
      console.log(reason, " Don't really know what to do here...");
      break;
    case "transport close":
      console.log(reason, " trying to reconnect...");
      console.log("Connected? -> ", socket.connected);
      break;
    case "ping timeout":
      console.log(reason, " trying to reconnect...");
      console.log("Connected? -> ", socket.connected);
      break;
    case "transport error":
      console.log(reason, " trying to reconnect...");
      console.log("Connected? -> ", socket.connected);
      break;
    default:
      console.log("Server trying to reconnect: acive = ", socket.active);
  }
});

socket.on("connect_error", (error) => {
  setTimeout(() => {
    socket.connect();
    console.log("Socket Reconnect Error: ", error.message);
  }, 10000);
});

export default socket;
