import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import("../views/Home.vue");
const Donate = () => import("../views/Donate.vue");
const Requests = () => import("../views/Requests.vue");
const Blogs = () => import("../views/Blogs.vue");
const Blog = () => import("../components/Blog.vue");
const Contact = () => import("../views/Contact.vue");
const VerseDisplay = () => import("../components/verseOfTheDay/VerseDisplay.vue");
const HowToListen = () => import("../views/HowToListen.vue");
const FeaturedSpeakers = () => import("../views/FeaturedSpeakers.vue");
const ProgramSchedule = () => import("../views/Calendar.vue");
const Success = () => import("../views/Success.vue");
const FullPagePlayer = () => import("../views/FullPagePlayer.vue");
const Cancel = () => import("../views/Cancel.vue");
const PrivacyPolicy = () => import("../views/PrivacyPolicy.vue");
const Message = () => import("../views/Message.vue");
const WeeklyMessage = () => import("../components/weeklySermon/WeeklyMessage.vue");
const Login = () => import("../views/Login.vue");
const Profile = () => import("../views/Profile.vue");
const Testimonies = () => import("../views/Testimonies.vue");
const Features = () => import("../views/Features.vue");
const Admin = () => import("../views/Admin.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // meta: {
    //   requiresAuth: true
    // },
    children: [
      {
        name: "Verse",
        path: "verse/:verseId",
        component: VerseDisplay,
        props: true,
        meta: {
          showModal: true,
        },
      },
    ],
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    // meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/feature",
    name: "Features",
    component: Features,
  },
  {
    path: "/testimonies/:query?",
    name: "Testimonies",
    component: Testimonies,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/cancel",
    name: "Cancel",
    component: Cancel,
  },
  {
    path: "/requests/:query?",
    name: "Requests",
    component: Requests,
  },
  {
    path: "/player",
    name: "FullPagePlayer",
    component: FullPagePlayer,
  },
  {
    path: "/blog",
    name: "Blogs",
    component: Blogs,
    children: [
      {
        path: ":blogSlug?",
        name: "Blog",
        component: Blog,
        props: true,
      },
    ],
  },

  {
    path: "/weekly-message",
    name: "Message",
    component: Message,
    children: [
      {
        path: ":messageSlug",
        name: "WeeklyMessage",
        component: WeeklyMessage,
        props: true,
      },
    ],
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/donate",
    name: "Donate",
    component: Donate,
  },
  {
    path: "/how-to-listen",
    name: "HowToListen",
    component: HowToListen,
  },
  {
    path: "/featured-speakers",
    name: "FeaturedSpeakers",
    component: FeaturedSpeakers,
  },
  {
    path: "/program-schedule",
    name: "ProgramSchedule",
    component: ProgramSchedule,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem('user');

//   if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
//     next('/');
//   }
//   next();
// });

export default router;
