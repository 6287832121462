import axios from 'axios';


const testimony = {
  namespaced: true,
  state: {
    testimonies: [],

  },
  mutations: {
    SET_TESTIMONIES(_state, _testimonies) {
      _state.testimonies = _testimonies;
    },
    CLEAR_TESTIMONIES(_state) {
      _state.testimonies = [];
    },
  },
  actions: {
    getTestimonies({ commit }) {
      // const url = "http://127.0.0.1:8000/testimonies";
      const url = "https://gwrapi.herokuapp.com/testimonies";
      axios
        .get(url)
        .then(res => {
          if (res?.data && Array.isArray(res?.data?.results)) {
            commit("SET_TESTIMONIES", res?.data);
          }
        })
        .catch(err => {
          console.error("getTestimones: ", err);
        });
    },
    getTestimonyPage({ commit }, page) {
      axios
        .get(page)
        .then(res => {
          if (res?.data && Array.isArray(res?.data?.results)) {
            commit("CLEAR_TESTIMONIES");
            commit("SET_TESTIMONIES", res?.data);
          }
        })
        .catch(err => {
          console.error('getTestimonyPage: ', err);
        });
    },
  }
}

export default testimony;
