import axios from "axios";

const slides = {
  namespaced: true,
  state: {
    slides: []
  },
  mutations: {
    SET_SLIDES(_state, _slides) {
      _state.slides = _slides;
    }
  },
  actions: {
    getSlides({ commit }) {
      axios
        .get("https://gwrapi.herokuapp.com/slides/current_slides")
        .then(res => {
          if (res?.data && Array.isArray(res?.data)) {
            commit("SET_SLIDES", res?.data);
          }
        })
        .catch(() => {
          try {
            const slideObj = [
              {
                alt_text: "Pure",
                id: 1,
                image: "https://gracewayradio.com/slide.06.jpg"
              },
              {
                alt_text: "Worship",
                id: 2,
                image: "https://gracewayradio.com/slide.07.jpg"
              },
              {
                alt_text: "Word",
                id: 3,
                image: "https://gracewayradio.com/slide.08.jpg"
              }
            ];
            commit("SET_SLIDES", slideObj);
          } catch (err) {
            console.error("getSlides: ", err);
          }
        });
    }
  }
};

export default slides;
